<template>
    <template v-if="item && item.inputEditor == '{{orgunit_lookup}}' || item.editor == 'orgunit_lookup'">
        <OOrgUnitsLookup v-bind="$attrs" :bind="sel=>apply(sel,item)" allowClear>
            <template #orgunit>
                <input type="text" :class="setSelectClass()" v-bind="$attrs" :disabled="props.item.readonly" :placeholder="item.placeHolder" v-model="item.displayValue"/>
            </template>  
        </OOrgUnitsLookup>
    </template>
    <template v-else-if="item && item.inputEditor == '{{object_lookup}}' || item.editor == 'object_lookup'">
        <OObjectsLookup :bind="sel=>apply(sel,item)" :objectsWhereClause="whereClause">   
            <template #target="{target}">
                <input type="text" :class="setSelectClass()" :ref="target" v-bind="$attrs" :disabled="props.item.readonly" :placeholder="item.placeHolder" v-model="item.displayValue"/>
            </template>
        </OObjectsLookup>
    </template>
    <template v-else>
       <ODataLookup v-if="vConfig" v-bind="$attrs" :disabled="props.item.readonly" :class="setSelectClass()" :bind="sel=>apply(sel,item)" :viewName="vConfig.viewName" :dataObject = "vConfig.dataObject" :placeholder="item.placeHolder" v-model="item.displayValue" :multiselect="vConfig.config.Multiselect">
            <OColumn v-for="col in vConfig.columns" :name = "col.name" :width="col.width"/>
        </ODataLookup> 
    </template>
</template>
<script setup>
    import { OObjectsLookup, OOrgUnitsLookup } from 'o365-system-lookups';
    import { getConfig } from './inputEditorsConfigs.ts';
    import { ref } from 'vue';

    const props = defineProps({
        item:Object,
        config:Object,
        addFormClasses:{
            type:Boolean,
            default:false
        }
    });

    const whereClause = ref(props?.item.item.WhereClause ? props.item.item.WhereClause : null);

    const vConfig = ref(null);

    if(props.config) {
        vConfig.value = props.config;
        if(vConfig.value.display){
            props.item.displayMember = vConfig.value.display;
        }
    }

    if(!props.config){
        getConfig(props.item.inputEditorParsed).then(pConfig=>{
            vConfig.value = pConfig;
            if(vConfig.value.display){
                props.item.displayMember = vConfig.value.display;
            }
    
        })
    }

    const apply = (sel,item)=>{
        if(!Object.keys(sel).length){
            item.value = null;
            item.displayValue = null;
            return;
        }
       
        if(vConfig.value.config.Multiselect){
            if(vConfig.value.display && sel[0].hasOwnProperty(vConfig.value.display)){
               item.displayValue = sel.map(x=>x[vConfig.value.display]).join(', ');
            }
            if(vConfig.value.valueMember && sel[0].hasOwnProperty(vConfig.value.valueMember)){
                item.value =  sel.map(x=>x[vConfig.value.valueMember]).join(', ');
                return;
            }

            if(vConfig.value.display && sel[0].hasOwnProperty(vConfig.value.display)){
                item.value = sel.map(x=>x[vConfig.value.display]).join(', ');
                return;
            }

            if(sel[0][item.column]) {
                item.value = sel.map(x=>x[item.column]).join(', ');
            
            }
            if(vConfig.value.id == "orgunit_lookup"){
                item.displayValue = sel.map(x=>x.Name).join(', ');
            }

            return;
        }

        if(vConfig.value.display && sel.hasOwnProperty(vConfig.value.display)){
            item.displayValue = sel[vConfig.value.display];
        }

        if(vConfig.value.id == "orgunit_lookup" || (vConfig.value.config?.Type == "OrgUnit") || vConfig.value.id == "object_lookup" || (vConfig.value.config?.Type == "Object")){
            item.displayValue = sel.Name;
            item.displayMember = vConfig.value.display;
        }

        if(vConfig.value.valueMember && sel.hasOwnProperty(vConfig.value.valueMember)){
            item.value = sel[vConfig.value.valueMember];
            return;
        }

        if(vConfig.value.display && sel.hasOwnProperty(vConfig.value.display)){
            item.value = sel[vConfig.value.display];
            return;
        }

        if(sel[item.column]) {
            item.value = sel[item.column];
        }

        if(sel.hasOwnProperty("ID")){
            item.value = sel.ID;
        }
    }

    const setSelectClass = () => {
        if(props.addFormClasses){
            return 'form-select';
        }
        return "lookup-icon text-truncate";
    }

</script>
